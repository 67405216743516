var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"overflow":"hidden"}},[_c('v-banner',{key:"1",staticClass:"text-body-2 black--text py-0",attrs:{"icon":"alarm_off","sticky":"","single-line":"","icon-color":"white","color":"amber","tile":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var dismiss = ref.dismiss;
return [_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":dismiss}},[_vm._v("Close")])]}}]),model:{value:(_vm.scheduleBanner),callback:function ($$v) {_vm.scheduleBanner=$$v},expression:"scheduleBanner"}},[_vm._v(" Psst! We noticed you've got schedules here but your flow's schedule isn't turned on; turn it on with the toggle at the top of the page to start scheduling flows! ")]),_c('div',{staticClass:"schedule-grid my-4"},[_c('div',{staticClass:"grid-container",class:{ 'grid-container-large': _vm.selectedClock === -1 }},[(_vm.hasPermission('create', 'run'))?_c('v-card',{staticClass:"clock-card",class:{ 'clock-card-large': _vm.selectedClock === -1 },style:({ 'pointer-events': _vm.selectedClock === -1 ? 'none' : 'auto' }),attrs:{"tile":"","ripple":false},nativeOn:{"click":function($event){_vm.selectedClock = -1}}},[_c('v-card-text',{staticStyle:{"height":"100%","overflow":"auto"},style:({ 'pointer-events': 'auto' })},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.selectedClock == -1)?_c('div',{key:"1",staticStyle:{"height":"100%"}},[_c('span',{staticClass:"text-h5 black--text"},[_vm._v("New schedule")]),_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_vm._v("Schedule")]),_c('v-tab',[_vm._v("Parameters")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 0),expression:"selectedTab === 0"}]},[_c('ClockForm',{attrs:{"flow-group-clocks":_vm.flowGroupClocks,"param":_vm.parameter,"timezone":this.timezone ||
                      Intl.DateTimeFormat().resolvedOptions().timeZone},on:{"cancel":function($event){_vm.selectedClock = null},"confirm":_vm.createClock}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 1),expression:"selectedTab === 1"}]},[(_vm.checkDefualtParameters(_vm.allDefaultParameters))?_c('p',{staticClass:"mt-8 text-body-1"},[_vm._v(" Checked parameters will override their corresponding defaults for runs generated from this schedule. ")]):_vm._e(),(_vm.checkDefualtParameters(_vm.allDefaultParameters))?_c('DictInput',{staticStyle:{"padding":"20px"},attrs:{"include-checkbox":"","dict":_vm.allDefaultParameters,"disable-edit":"","allow-reset":""},model:{value:(_vm.parameter),callback:function ($$v) {_vm.parameter=$$v},expression:"parameter"}}):(!_vm.checkDefualtParameters(_vm.allDefaultParameters))?_c('div',{staticClass:"mt-8 text-body-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.flow.name))]),_vm._v(" has no default parameters. ")]):_vm._e()],1)],1):_c('div',{key:"2",staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"cursor":"pointer","height":"100%","user-select":"none"}},[_c('v-icon',{attrs:{"color":"codePink","x-large":""}},[_vm._v("alarm_add")]),_c('div',{staticClass:"text-h6 mt-2"},[_vm._v("New schedule")])],1)])],1)],1):_vm._e()],1),_vm._l((_vm.clocks),function(clock,i){return _c('div',{key:i,staticClass:"grid-container",class:{ 'grid-container-large': _vm.selectedClock === i }},[_c('v-card',{staticClass:"clock-card text-truncate",class:{ 'clock-card-large': _vm.selectedClock === i },style:({
          'border-left':
            clock.scheduleType == 'flow'
              ? '4px solid var(--v-primary-base) !important'
              : '',
          'border-left-color': 'var(--v-primary-base) !important',
          opacity:
            _vm.hasFlowGroupSchedule && clock.scheduleType == 'flow' ? '0.5' : ''
        }),attrs:{"color":"appForeground","tile":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.selectedClock == i)?_c('div',{key:"1",staticStyle:{"height":"100%","overflow":"auto","padding":"20px","white-space":"pre-wrap"}},[_c('span',{staticClass:"text-h5 black--text"},[_vm._v("Modify schedule")]),_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_vm._v("Schedule")]),_c('v-tab',[_vm._v("Parameters")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 0),expression:"selectedTab === 0"}]},[_c('ClockForm',{attrs:{"flow-group-clocks":_vm.flowGroupClocks,"clock":clock,"cron":clock.cron,"param":_vm.parameter,"interval":clock.interval,"timezone":_vm.timezoneVal(clock)},on:{"cancel":function($event){_vm.selectedClock = null},"confirm":_vm.createClock}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab === 1),expression:"selectedTab === 1"}]},[(!_vm.checkDefualtParameters(_vm.allDefaultParameters))?_c('div',{staticClass:"mt-8 text-body-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.flow.name))]),_vm._v(" has no default parameters. ")]):_c('DictInput',{staticStyle:{"padding":"20px"},attrs:{"dict":_vm.removeDoubleParam(clock.parameter_defaults),"default-checked-keys":Object.keys(
                    clock.parameter_defaults
                      ? clock.parameter_defaults
                      : _vm.allDefaultParameters
                  ),"include-checkbox":"","disable-edit":"","allow-reset":""},model:{value:(_vm.parameter),callback:function ($$v) {_vm.parameter=$$v},expression:"parameter"}})],1)],1):_c('div',{key:"2",staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%","white-space":"pre-wrap"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column align-start",attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex align-start justify-start pa-4 font-weight-bold text-h5",staticStyle:{"height":"90%"},attrs:{"no-gutters":""}},[(clock.type == 'CronClock')?_c('CronClock',{attrs:{"cron":clock.cron,"timezone":_vm.timezoneVal(clock)}}):(clock.type == 'IntervalClock')?_c('IntervalClock',{attrs:{"interval":clock.interval}}):_c('div',[_vm._v("Unrecognized clock")])],1),_c('div',{staticClass:"pb-3 pl-4 mt-auto"},[(clock.scheduleType == 'flow')?_c('v-tooltip',{attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"px-2 rounded-sm mr-1",attrs:{"label":"","x-small":""}},on),[_vm._v(" Read-only ")])]}}],null,true)},[_vm._v(" This schedule was set in your Flow's code so it can't be modifed. ")]):_vm._e(),(
                      clock.parameter_defaults &&
                        Object.keys(clock.parameter_defaults).length !== 0
                    )?_c('v-tooltip',{attrs:{"max-width":"200","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(
                          clock.parameter_defaults &&
                            Object.keys(clock.parameter_defaults).length !== 0
                        )?_c('v-chip',_vm._g({staticClass:"px-2 rounded-sm mr-1",attrs:{"label":"","color":"codeBlue lighten-1","dark":"","x-small":""}},on),[_vm._v(" Default parameters ")]):_vm._e()]}}],null,true)},[_vm._v(" This schedule overrides default parameters. ")]):_vm._e(),(
                      _vm.hasFlowGroupSchedule && clock.scheduleType == 'flow'
                    )?_c('v-tooltip',{attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"px-2 rounded-sm mr-1",attrs:{"label":"","x-small":""}},on),[_vm._v(" Not scheduled ")])]}}],null,true)},[_vm._v(" The existing flow group schedule will overide this schedule. ")]):_vm._e()],1)]),_c('v-col',{staticClass:"d-flex align-center flex-column justify-end pa-1",staticStyle:{"border-left":"1px solid var(--v-utilGrayLight-base)","height":"100%"},attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('div',_vm._g({staticClass:"my-1 mb-auto d-block text-decoration-none",on:{"focus":function($event){clock.contextMenu = true},"blur":function($event){clock.contextMenu = false}}},on),[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v(" info ")])],1)]}}],null,true),model:{value:(clock.contextMenu),callback:function ($$v) {_vm.$set(clock, "contextMenu", $$v)},expression:"clock.contextMenu"}},[_c('v-card',{staticClass:"pa-0",attrs:{"tile":"","max-width":"320"}},[_c('v-card-text',{staticClass:"pb-0"},[(clock.scheduleType == 'flow')?_c('p',[_c('v-alert',{attrs:{"border":"left","colored-border":"","elevation":"0","type":"warning","dense":"","icon":"warning","max-width":"500"}},[_c('div',{staticClass:"text-body-2 ma-0"},[_vm._v("This schedule was set in your Flow's code so it can't be modifed.")]),(_vm.hasFlowGroupSchedule)?_c('div',{staticClass:"text-body-2 ma-0 mt-2"},[_vm._v(" The existing flow group schedule will overide this schedule. ")]):_vm._e()])],1):_vm._e(),_c('p',[_vm._v(" This schedule was created as a"+_vm._s('cron' in clock ? ' Cron' : 'date' in clock ? ' Date' : 'n Interval')+" clock with a value of "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(clock.cron || clock.interval || clock.date)+_vm._s(clock.interval ? ' µs' : '')+" ")])]),_c('p',{staticClass:"mt-1"},[_c('ExternalLink',{attrs:{"href":("https://docs.prefect.io/core/concepts/schedules.html#" + ('cron' in clock
                                ? 'cron'
                                : 'date' in clock
                                ? 'date'
                                : 'interval') + "-clocks")},on:{"click":function($event){clock.contextMenu = false}}},[_vm._v(" Visit the docs ")]),_vm._v(" to learn more about "+_vm._s('cron' in clock ? 'Cron' : 'date' in clock ? 'Date' : 'Interval')+" clocks on Prefect schedules. ")],1)]),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){clock.contextMenu = false}}},[_vm._v(" Close ")])],1)],1)],1),(clock.scheduleType == 'flow-group')?_c('v-tooltip',{attrs:{"max-width":"200","background-opacity":1,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [(_vm.hasPermission('update', 'run'))?_c('v-btn',_vm._g({staticClass:"my-1",attrs:{"icon":"","fab":"","color":"primary lighten-2","x-small":""},nativeOn:{"click":function($event){_vm.selectedClock = i}}},on),[_c('v-icon',[_vm._v(" edit ")])],1):_vm._e()]}}],null,true)},[_vm._v(" Modify this schedule ")]):_vm._e(),(clock.scheduleType == 'flow-group')?_c('v-tooltip',{attrs:{"max-width":"300","background-opacity":1,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [(_vm.hasPermission('delete', 'run'))?_c('v-btn',_vm._g({staticClass:"mt-1",attrs:{"icon":"","fab":"","color":"red lighten-2","x-small":""},nativeOn:{"click":function($event){_vm.removeScheduleDialog = true
                        _vm.clockToRemove = i}}},on),[_c('v-icon',[_vm._v(" delete ")])],1):_vm._e()]}}],null,true)},[_vm._v(" Remove this schedule ")]):_vm._e()],1)],1)],1)])],1)],1)}),_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '500' },"disabled":_vm.loading,"loading":_vm.loading,"title":"Remove schedule"},on:{"cancel":function($event){_vm.clockToRemove = null},"confirm":_vm.deleteClock},model:{value:(_vm.removeScheduleDialog),callback:function ($$v) {_vm.removeScheduleDialog=$$v},expression:"removeScheduleDialog"}},[_vm._v(" You can always recreate this schedule later. ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }