var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2 pb-6 mt-2",attrs:{"outlined":""}},[_c('CardTitle',{attrs:{"title":"Flow Settings","icon":"settings"}}),_c('v-card-text',{staticClass:"pl-12",staticStyle:{"max-width":"1000px"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 primary--text"},[_vm._v(" Project ")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-select',{attrs:{"items":_vm.sortedProjects,"item-value":"id","item-text":"name","label":"Select project","hide-details":"","single-line":"","menu-props":{ contentClass: 'custom-list-item' },"prepend-inner-icon":"pi-project mr-1","disabled":_vm.permissionsCheck || _vm.loading.project},model:{value:(_vm.selected.projectId),callback:function ($$v) {_vm.$set(_vm.selected, "projectId", $$v)},expression:"selected.projectId"}})],1)]}}])},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to move flows between projects. ")]):_c('span',[_vm._v(" Move this flow to a new project. ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"4","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"display":"inline-block"}},on),[_c('v-btn',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'mt-4',attrs:{"disabled":_vm.permissionsCheck ||
                        !_vm.projectHasChanged ||
                        _vm.loading.project,"loading":_vm.loading.project,"small":"","color":"primary"},on:{"click":_vm._handleProjectChange}},[_vm._v(" Save ")])],1)]}}])},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to move flows between projects. ")]):_c('span',[_vm._v(" Move this flow to a new project. ")])])],1)],1),_c('div',{staticClass:"mt-4"},[_vm._v(" Projects are a method of organizing your Flows. Read more about projects "),_c('a',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/projects.html#creating-a-project","target":"_blank"}},[_vm._v(" here ")]),_c('sup',[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v(" open_in_new ")])],1),_vm._v(" . ")])],1)],1),(_vm.isCloud)?_c('v-row',{staticClass:"mt-8"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 primary--text"},[_vm._v(" Version Locking ")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({staticClass:"pb-1",staticStyle:{"display":"inline-block"}},on),[_c('v-switch',{attrs:{"hide-details":"","color":"primary","loading":_vm.loading.versionLocking,"disabled":_vm.permissionsCheck ||
                    !_vm.hasPermission('feature', 'version-locking') ||
                    _vm.loading.versionLocking},on:{"change":_vm._handleVersionLockingChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" Version Locking "),_c('span',{staticClass:"font-weight-medium",class:_vm.isVersionLocked
                          ? 'prefect--text'
                          : 'grey--text text--darken-2'},[_vm._v(" "+_vm._s(_vm.isVersionLocked ? 'Enabled' : 'Disabled')+" ")])])]},proxy:true}],null,true),model:{value:(_vm.selected.versionLockingEnabled),callback:function ($$v) {_vm.$set(_vm.selected, "versionLockingEnabled", $$v)},expression:"selected.versionLockingEnabled"}})],1)]}}],null,false,943521472)},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to modify flow settings. ")]):_vm._e(),(!_vm.hasPermission('feature', 'version-locking'))?_c('span',[_vm._v(" Your team doesn't have access to version locking. ")]):_c('span',[_vm._v(" "+_vm._s(_vm.isVersionLocked ? 'Disable' : 'Enable')+" version locking for this flow and its tasks. ")])]),_c('div',{staticClass:"mt-4"},[_vm._v(" Version locking ensures that this flow and its tasks run "),_c('i',[_vm._v("just once")]),_vm._v(". Read more about it "),_c('a',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/flows.html#flow-settings","target":"_blank"}},[_vm._v(" here ")]),_c('sup',[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v(" open_in_new ")])],1),_vm._v(". ")])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 primary--text"},[_vm._v(" Heartbeat ")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('div',_vm._g({staticClass:"pb-1",staticStyle:{"display":"inline-block"}},on),[_c('v-switch',{attrs:{"hide-details":"","color":"primary","loading":_vm.loading.heartbeat,"disabled":_vm.permissionsCheck || _vm.loading.heartbeat},on:{"change":_vm._handleHeartbeatChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" Heartbeat "),_c('span',{staticClass:"font-weight-medium",class:_vm.isHeartbeatEnabled
                          ? 'prefect--text'
                          : 'grey--text text--darken-2'},[_vm._v(" "+_vm._s(_vm.isHeartbeatEnabled ? 'Enabled' : 'Disabled')+" ")])])]},proxy:true}],null,true),model:{value:(_vm.selected.heartbeatEnabled),callback:function ($$v) {_vm.$set(_vm.selected, "heartbeatEnabled", $$v)},expression:"selected.heartbeatEnabled"}})],1)]}}])},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to modify flow settings. ")]):_c('span',[_vm._v(" "+_vm._s(_vm.isHeartbeatEnabled ? 'Disable' : 'Enable')+" heartbeats for this flow. ")])]),_c('div',{staticClass:"mt-4"},[_vm._v(" Heartbeats are sent by Prefect Core every 30 seconds and are used to confirm the flow run and its associated task runs are healthy; runs missing four heartbeats in a row are marked as "),_c('v-chip',{staticClass:"mx-1",attrs:{"label":"","small":"","color":"Failed","dark":""}},[_vm._v(" Failed ")]),_vm._v(" by the "),_c('a',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/services.html#zombie-killer","target":"_blank"}},[_vm._v(" Zombie Killer ")]),_c('sup',[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v(" open_in_new ")])],1),_vm._v(". You can read more about heartbeats "),_c('a',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/flows.html#flow-settings","target":"_blank"}},[_vm._v("here ")]),_c('sup',[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v(" open_in_new ")])],1),_vm._v(". ")],1)],1)],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 primary--text"},[_vm._v(" Lazarus Process ")])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('div',_vm._g({staticClass:"pb-1",staticStyle:{"display":"inline-block"}},on),[_c('v-switch',{attrs:{"hide-details":"","color":"primary","loading":_vm.loading.lazarus,"disabled":_vm.permissionsCheck || _vm.loading.lazarus},on:{"change":_vm._handleLazarusChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" Lazarus "),_c('span',{staticClass:"font-weight-medium",class:_vm.isLazarusEnabled
                          ? 'prefect--text'
                          : 'grey--text text--darken-2'},[_vm._v(" "+_vm._s(_vm.isLazarusEnabled ? 'Enabled' : 'Disabled')+" ")])])]},proxy:true}],null,true),model:{value:(_vm.selected.lazarusEnabled),callback:function ($$v) {_vm.$set(_vm.selected, "lazarusEnabled", $$v)},expression:"selected.lazarusEnabled"}})],1)]}}])},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to modify flow settings. ")]):_c('span',[_vm._v(" "+_vm._s(_vm.isLazarusEnabled ? 'Disable' : 'Enable')+" Lazarus for this flow. ")])]),_c('div',{staticClass:"mt-4"},[_vm._v(" The Lazarus process is responsible for rescheduling distressed flow runs. Read more about Lazarus "),_c('a',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/services.html#lazarus","target":"_blank"}},[_vm._v("here ")]),_c('sup',[_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v(" open_in_new ")])],1),_vm._v(". ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }