var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2 mt-2",attrs:{"loading":_vm.loading,"outlined":""}},[_c('CardTitle',{attrs:{"title":"Default Parameters","icon":"perm_data_setting"}}),_c('v-card-text',{staticClass:"pl-12"},[_c('div',{staticClass:"text-body-1",staticStyle:{"max-width":"1000px"}},[_vm._v(" You can override the default parameter values on your Flow at the Flow Group level. If provided, they take precedence over any default parameters set at flow registration, and apply to all versions of your Flow. Here you can set new flow group default parameters or reset them to the parameters you set at registration. ")]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onD = ref.onD;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"pb-1 text-center",class:_vm.containerClass,style:({
                        display:
                          !_vm.defaultParameters || !_vm.defaultParameters.length
                            ? 'block'
                            : 'inline-block'
                      })},on),[(
                          !!_vm.defaultParameters && !!_vm.defaultParameters.length
                        )?_c('v-btn',_vm._g({attrs:{"color":"primary","disabled":_vm.permissionsCheck},on:{"click":function($event){_vm.editAll = true}}},onD),[_c('v-icon',{attrs:{"left":""}},[_vm._v("edit")]),_c('span',[_vm._v("JSON Edit")])],1):_vm._e()],1)]}}],null,true)},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to create or edit parameters. ")]):_c('span',[_vm._v(" Use JSON to edit parameters for this flow group. ")])]),(!_vm.defaultParameters || !_vm.defaultParameters.length)?_c('div',{staticClass:"mt-8 text-body-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedFlow.name))]),_vm._v(" has no default parameters. ")]):_vm._e()]}}]),model:{value:(_vm.editAll),callback:function ($$v) {_vm.editAll=$$v},expression:"editAll"}},[(_vm.editAll)?_c('v-card',{staticClass:"pa-2",attrs:{"loading":_vm.loading,"tile":""}},[_c('CardTitle',{attrs:{"title":"Parameters"}}),_c('v-card-text',{staticClass:"pl-12"},[_c('JsonInput',{ref:"parameterRef",attrs:{"selected-type":"json","new-parameter-input":_vm.newParameterInput,"disabled":_vm.selectedFlow.archived,"data-cy":"flow-group-parameter-input"},on:{"input":function($event){_vm.errorInParameterInput = false}},model:{value:(_vm.parameterInput),callback:function ($$v) {_vm.parameterInput=$$v},expression:"parameterInput"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.reset}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.setAll}},[_vm._v(" Set Params ")])],1)],1):_vm._e()],1),(!!_vm.paramsToReset)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","color":"red"},on:{"click":_vm.clearFlowGroupParameters}},on),[_vm._v(" Reset all")])]}}],null,false,2199366273)},[_c('span',[_vm._v("Reset all default parameters to defaults set at registration")])]):_vm._e()],1)],1)],1)],1),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.expandedPanelKeys),callback:function ($$v) {_vm.expandedPanelKeys=$$v},expression:"expandedPanelKeys"}},_vm._l((_vm.parametersOptions),function(parameter,i){return _c('v-expansion-panel',{key:i,attrs:{"height":"90px"}},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"text":"","disabled":_vm.checkSame(parameter)},on:{"click":function($event){$event.stopPropagation();return _vm.switchVal(parameter)}}},[_c('v-icon',{staticClass:"ml-0 mr-2",attrs:{"small":""}},[_vm._v("fa-undo")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Reset to the parameters set at flow registration")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.individualOpenEdit(i)}}},[(_vm.expandedPanelKeys.includes(i))?_c('v-icon',[_vm._v("expand_less")]):_c('v-icon',[_vm._v("expand_more")])],1)],1)]}}],null,true)},[(_vm.permissionsCheck)?_c('span',[_vm._v(" You don't have permission to create or edit parameters. ")]):_c('span',[_vm._v(" Edit this parameter. ")])])]},proxy:true}],null,true)},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(_vm._s(parameter.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Default Value: "+_vm._s(_vm.defaultVal(parameter))+" ")])],1)],1)],1),_c('v-expansion-panel-content',[_c('ParametersForm',{attrs:{"parameter":parameter,"flow-group":_vm.flowGroup},on:{"cancel":function($event){return _vm.reset(i)},"updated":_vm.update}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }